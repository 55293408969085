import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as R from "ramda";
import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate } from "react-router-dom-v5-compat";

import Modal from "components/layouts/Modal";
import HotKeysProvider, { useHotKeyContext } from "contexts/hotKeys";
import useRegisteredHotKey from "hooks/useRegisteredHotKey";

import styles from "./Hotkeys.scss";
import { SuperuserFeature } from "./privilegedUserFeatures";

const Shortcut = ({ name, shortcuts }) => (
  <li>
    <div className={styles.shortcut}>{name}</div>
    <div className={styles.keys}>
      {shortcuts.map((shortcut) => (
        <div key={shortcut} className={styles.key}>
          {shortcut}
        </div>
      ))}
    </div>
  </li>
);

const Help = ({ hotkeys }) => {
  const bySection = R.groupBy(R.prop("section"), hotkeys);
  const classNames = {
    contentContainer: styles.helpBoxContainer,
    content: styles.helpBox,
  };

  return (
    <Modal classNames={classNames} hideClose>
      <div className={styles.header}>Shortcuts</div>
      {R.keys(bySection).map((section) => (
        <div className={styles.section} key={section}>
          <div className={styles.sectionHeader}>{section} shortcuts</div>
          <ul>
            {bySection[section].map((keyConfig) => (
              <Shortcut
                key={keyConfig.key}
                name={keyConfig.description}
                shortcuts={(keyConfig.helpKey || keyConfig.key).split("+")}
              />
            ))}
          </ul>
        </div>
      ))}
    </Modal>
  );
};

const GotToLabel = ({ keyName, displayName }) => (
  <li>
    (<span className={styles.key}>{keyName}</span>){displayName}
  </li>
);

const GoTo = ({ close }) => {
  const navigate = useNavigate();
  const { gotoItems } = useHotKeyContext();
  const [openInTab, setOpenInTab] = useState(false);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        setOpenInTab(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Shift") {
        setOpenInTab(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const itemsBySection = useMemo(() => R.groupBy(R.prop("section"), gotoItems), [gotoItems]);

  const sections = useMemo(() => R.uniq(gotoItems.map((item) => item.section)), [gotoItems]);
  console.log("sections", sections, gotoItems);

  const handleHotkey = useCallback(
    (event) => {
      const item = gotoItems.find(
        (item) => item.key === event.key.toLowerCase() || item.keyName === event.key,
      );
      if (item) {
        if (event.shiftKey) {
          const tab = window.open(item.path, "_blank");
          close();
          tab.focus();
        } else {
          navigate(item.path);
          // hack to force home page refresh: https://github.com/opencounter/opencounter/issues/4884
          if (item.key === "h") navigate(0);
          close();
        }
      }
    },
    [gotoItems, navigate, close],
  );

  const hotkeyString = useMemo(
    () => gotoItems.map((item) => (openInTab ? `shift+${item.key}` : item.key)).join(","),
    [gotoItems, openInTab],
  );

  useHotkeys(hotkeyString, handleHotkey, [handleHotkey]);

  const classNames = {
    contentContainer: styles.helpBoxContainer,
    content: styles.goto,
  };

  return (
    <Modal classNames={classNames} hideClose>
      <div>
        <div className={styles.header}>{openInTab ? "Open in new tab:" : "Go to:"}</div>
        {sections.map((section, index) => (
          <React.Fragment key={section}>
            {index > 0 && <hr />}
            <div className={styles.sectionHeader}>{section}</div>
            <ul>
              {itemsBySection[section].map(({ key, keyName, displayName, name }) => {
                const keyDisplayName = openInTab ? (keyName || key).toUpperCase() : keyName || key;
                return <GotToLabel key={name} keyName={keyDisplayName} displayName={displayName} />;
              })}
            </ul>
          </React.Fragment>
        ))}
      </div>
    </Modal>
  );
};

const DefaultHotKeys = () => {
  useRegisteredHotKey("shift+/", ({ setOverlay }) => setOverlay(Help), {
    section: "Site",
    description: "Open help",
    helpKey: "?",
  });

  useRegisteredHotKey("esc", ({ close }) => close(), {
    alwaysEnabled: true,
    section: "Site",
    description: "Close",
  });

  useRegisteredHotKey("ctrl+q", ({ setEnabled }) => setEnabled(false), {
    section: "Site",
    description: "Disable shortcuts",
  });

  useRegisteredHotKey("g", ({ setOverlay }) => setOverlay(GoTo), {
    section: "Site",
    description: "Go to",
  });

  return null;
};

const MaybeHotKeys = ({ children }) => (
  <HotKeysProvider>
    <SuperuserFeature>
      <DefaultHotKeys />
    </SuperuserFeature>
    {children}
  </HotKeysProvider>
);

export default MaybeHotKeys;
