import React, { useCallback, useState } from "react";
import classnames from "classnames";
import camelcase from "lodash.camelcase";
import PropTypes from "prop-types";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { createRenewal } from "actions";
import { Button } from "components/Button";
import ExternalLink from "components/ExternalLink";
import Icon, { CircularIcon } from "components/Icon";
import Loader, { InlineLoader } from "components/Loader";
import MailToLink from "components/MailToLink";
import Markdown, { STEPS_CONTEXT } from "components/Markdown";
import TelephoneLink from "components/TelephoneLink";
import PizzaTracker from "components/admin/applications/show/PizzaTracker";
import Fields from "components/guides/Fields";
import Modal from "components/layouts/Modal";
import SendMessageBadge from "components/messaging/SendMessageBadge";
// import { LayoutWithLock } from "components/project_form/Field";
import SummaryTable from "components/projects/SummaryTable";
import * as Layout from "components/projects/pages/Layout";
import RenewalOutOfDate from "components/requirementApplication/RenewalOutOfDate";
import RenewalTimeline from "components/requirementApplication/RenewalTimeline";
import RenewalValidity from "components/requirementApplication/RenewalValidity";
import SkeuomorphicPaper from "components/utilities/SkeuomorphicPaper";
import { StickyNoteBubble } from "components/utilities/StickyNote";
import Text, { useText } from "containers/Text";
import LogEntries from "containers/admin/requirement_application/LogEntries";
import { useApplicationHistory } from "containers/admin/requirement_application/withHistory";
import HelpBar from "containers/guides/HelpBar";
import Download from "containers/projects/applications/Download";
import { useAnswerContext } from "containers/withAnswerContext";
import { useSession } from "contexts/session";
import useParam from "hooks/useParam";
import { useRenewal } from "queries/renewals";
import { getAddress, getPhone, selectDepartmentForRequirement } from "reducers/departments";
import {
  getRequirementApplicationByRequirementID,
  selectProjectRequirementSections,
} from "reducers/projects";
import { getExpiry, getRenewalDue } from "reducers/renewals";
import {
  ISSUED,
  NO_LONGER_REQUIRED,
  PAYABLE,
  REJECTED,
  REVOKED,
  SUBMITTED,
  SUBMITTED_EXTERNAL,
  getCurrentState,
  getInvoiceID,
  getStateHistory,
  getStateMetadata,
  isNoLongerRequired,
  isPayable,
  wasIssued,
  wasProcessed,
  wasSubmitted,
} from "reducers/requirementApplications";
import {
  getBeforeYouApplyMarkdown,
  getDescription,
  getDownloadURL,
  getName,
  getNextStepsMarkdown,
  getProcessingTime,
  getSlug,
  isAccelaCitizenAccess,
  isExternalLinkPerDepartment,
  isFreeform,
  isInPerson,
  isIntegrated,
  isMailIn,
  isPayableOnIssuance,
  isIssuable as isRequirementIssuable,
  isStandaloneFee,
  selectRequirementByID,
} from "reducers/requirements";
import { getAccelaACAGuidanceMarkdown, isAccelaEnabled, selectTenant } from "reducers/tenant";
import { unversionedAPI } from "services/api";
import { reportError } from "services/errorReporter";
import panelStyles from "sharedStyles/Panel.scss";
import { isBlank } from "utils/func";
import {
  departmentPropType,
  requirementApplicationPropType,
  requirementPropType,
} from "utils/sharedPropTypes";
import { modalBackgroundColor } from "utils/sharedStyles";
import { formatDateStringAsDayFullMonth, now } from "utils/time";
import { isAbsolute } from "utils/urls";

import styles from "./ApplicationDetails.scss";
import ReadOnlyBadge from "./ReadOnlyBadge";

const {
  project: { pushToAccela },
} = unversionedAPI;

const Instruction = ({ className, icon, titleText, button, children }) => (
  <div className={classnames(styles.instruction, className)}>
    <div className={styles.icon}>
      <CircularIcon icon={icon} size="xl" inverse bgColor="#eaeaea" />
    </div>
    <h4 className={styles.titleContainer}>{titleText}</h4>
    {button && <div className={styles.buttonContainer}>{button}</div>}
    {children && <div className={styles.contentContainer}>{children}</div>}
  </div>
);

const Section = ({ className, titleText, children }) => (
  <div className={classnames(styles.section, className)}>
    <h2>{titleText}</h2>
    {children}
  </div>
);

function hasAdditionalInfo(department) {
  const { url, address, phone, email } = department;
  return !R.all(isBlank, [url, address, phone, email]);
}

const AdditionalInfo = ({ department }) => {
  if (!hasAdditionalInfo(department)) return null;

  const { url, address, phone, email } = department;
  return (
    <Section
      className={styles.additionalInfoSection}
      titleText={<Text t="projects.application.additional_info" />}
    >
      <div id="additional-information" />
      {url && (
        <div className={styles.additionalInfoItem}>
          <Icon icon="mouse-pointer" fw />
          <span>
            <ExternalLink className={styles.link} href={url} />
          </span>
        </div>
      )}
      {email && (
        <div className={styles.additionalInfoItem}>
          <MailToLink email={email} inline />
        </div>
      )}
      {address && (
        <div className={styles.additionalInfoItem}>
          <Icon icon="building" fw />
          <span>{address}</span>
        </div>
      )}
      {phone && (
        <div className={styles.additionalInfoItem}>
          <Icon icon="phone" fw />
          <span>
            <TelephoneLink number={phone} />
          </span>
        </div>
      )}
    </Section>
  );
};
AdditionalInfo.propTypes = { department: departmentPropType.isRequired };

const RenewalSection = ({ requirement, application, renewal }) => {
  const dispatch = useDispatch();
  const { project_id: projectID } = application;
  const onRenew = useCallback(
    () =>
      dispatch(
        createRenewal({
          projectID,
          requirement,
        }),
      ),
    [dispatch, projectID, requirement],
  );

  if (!renewal.renewable) return null;

  const wasDerivedSubmitted = !!renewal.derivedSubmittedAt;
  const renewalMessage = wasDerivedSubmitted ? "submitted" : renewal.renewalState;
  return (
    <>
      <div className={styles[`${camelcase(renewal.renewalState)}Section`]}>
        <h2>
          <Text
            t={`projects.application.renewal_message.${renewalMessage}`}
            dueDate={formatDateStringAsDayFullMonth(getRenewalDue(renewal))}
            expiryDate={formatDateStringAsDayFullMonth(getExpiry(renewal))}
            requirementName={getName(requirement)}
          />
        </h2>

        {!wasDerivedSubmitted && (
          <Button onClick={onRenew} withSpinner data-renew>
            <Text t="projects.application.renew" />
          </Button>
        )}
      </div>

      {renewal.derivedState === ISSUED && !requirement.disable_issuance && (
        <IssuedSection
          application={application}
          requirement={requirement}
          canonicalProjectID={renewal.canonicalProjectID}
        />
      )}
    </>
  );
};
RenewalSection.propTypes = {
  canonicalProjectID: PropTypes.number.isRequired,
  application: requirementApplicationPropType.isRequired,
  requirement: requirementPropType.isRequired,
  derivedState: PropTypes.string.isRequired,
};

const Status = ({ requirement, application, department, currentState, projectID, contextType }) => {
  if (currentState === NO_LONGER_REQUIRED)
    return (
      <div className={styles.noLongerRequiredSection}>
        <div className={styles.subHeading}>
          <Text t="projects.application.no_longer_required_leader" />
        </div>
        <Text t="projects.application.no_longer_required_instructions" />
      </div>
    );

  if (currentState === REJECTED) return <RejectedSection application={application} />;

  if (currentState === REVOKED) return <RevokedSection application={application} />;

  if (currentState === ISSUED && !requirement.disable_issuance)
    return (
      <IssuedSection
        application={application}
        requirement={requirement}
        canonicalProjectID={projectID}
      />
    );

  if (currentState === PAYABLE)
    return (
      <PayableSection projectID={projectID} requirement={requirement} application={application} />
    );

  if (currentState === SUBMITTED)
    return (
      <SubmittedSection
        requirement={requirement}
        application={application}
        canonicalProjectID={projectID}
      />
    );
  if (currentState === SUBMITTED_EXTERNAL)
    return <AccelaTransferSuccess application={application} />;

  if (!wasSubmitted(application)) {
    return (
      <ApplySection
        projectID={projectID}
        requirement={requirement}
        application={application}
        department={department}
        contextType={contextType}
      />
    );
  }

  if (isStandaloneFee(requirement))
    return (
      <div className={styles.unSubmittableSection}>
        <Text t="projects.application.standalone_fee_instructions" />
      </div>
    );

  return null;
};

const PrePostApply = ({
  asInstruction = false,
  requirementApplicationID,
  projectID,
  markdown,
  post = false,
}) => {
  if (isBlank(markdown)) return null;

  const markdownChildren = (
    <Markdown
      className={styles.beforeYouApply}
      source={markdown}
      context={STEPS_CONTEXT}
      projectID={projectID}
      requirementApplicationID={requirementApplicationID}
    />
  );

  if (asInstruction)
    return (
      <Instruction
        icon="info"
        titleText={
          <Text
            t={
              post
                ? "projects.application.after_you_apply"
                : "projects.application.before_you_apply"
            }
          />
        }
        children={markdownChildren}
      />
    );
  return markdownChildren;
};

const ApplySection = ({ projectID, requirement, application, department, contextType }) => {
  if (isStandaloneFee(requirement)) return null;

  const beforeYouApplyMarkdown = getBeforeYouApplyMarkdown(requirement);
  const afterYouApplyMarkdown = requirement.after_you_apply;
  return (
    <>
      <div className={styles.section}>
        <h2 id="apply">
          <Text t="projects.application.apply" />
        </h2>
        {isFreeform(requirement) ? (
          <PrePostApply
            projectID={projectID}
            requirementApplicationID={application.id}
            markdown={beforeYouApplyMarkdown}
          />
        ) : (
          <>
            <PrePostApply
              projectID={projectID}
              requirementApplicationID={application.id}
              markdown={beforeYouApplyMarkdown}
              asInstruction
            />
            <ApplyInstructions
              projectID={projectID}
              application={application}
              department={department}
              contextType={contextType}
              requirement={requirement}
            />
          </>
        )}
      </div>
      {afterYouApplyMarkdown && (
        <div id="after-application" className={styles.section}>
          <h2 id="apply">
            <Text t="projects.application.after_you_apply" />
          </h2>
          <PrePostApply
            projectID={projectID}
            requirementApplicationID={application.id}
            markdown={afterYouApplyMarkdown}
            post
          />
        </div>
      )}
    </>
  );
};

const LogEntriesSection = ({ application }) => (
  <div className={styles.logEntriesSection}>
    <h2>
      <Text t="projects.application.history" />
    </h2>
    <div className={styles.logEntries}>
      <LogEntries requirementApplicationID={application.id} context="handoff" applicantContext />
    </div>
  </div>
);
LogEntriesSection.propTypes = {
  application: requirementApplicationPropType.isRequired,
};

const QuickNav = ({ hasAfterYouApply, hasFeeFields, hasAdditionalInfo }) => (
  <div className={classnames(panelStyles.container, styles.quickNav)}>
    <div className={panelStyles.header}>
      <Text t="projects.application.fees.nav_title" />
    </div>
    <div className={panelStyles.content}>
      <ul className={styles.navList}>
        <li>
          <a href="#apply">
            <Text t="projects.application.apply" />
          </a>
        </li>
        {hasAfterYouApply && (
          <li>
            <a href="#after-application">
              <Text t="projects.application.after_you_apply" />
            </a>
          </li>
        )}
        {hasFeeFields && (
          <li>
            <a href="#fee-assessment">
              <Text t="projects.application.fees.section_title" />
            </a>
          </li>
        )}
        {hasAdditionalInfo && (
          <li>
            <a href="#additional-information">
              <Text t="projects.application.fees.additional_information" />
            </a>
          </li>
        )}
      </ul>
    </div>
  </div>
);

const ApplicationDetails = ({ returnTo = null, contextType = "", requirementID = null }) => {
  const { record: project, readOnly } = useAnswerContext();
  const { id: projectID } = project;
  const requirementIDParam = useParam("requirementID", Number);
  const requirement = useSelector((state) =>
    selectRequirementByID(state, requirementID != null ? requirementID : requirementIDParam),
  );
  const application = getRequirementApplicationByRequirementID(project, requirement.id) || {};

  const assessmentFields = useSelector((state) => {
    const section = selectProjectRequirementSections(state, project, requirement, {
      withFeeFields: true,
    }).find((s) => s.key === "fee_scoping");
    if (section) {
      return R.map(R.assoc("required", false), section.fields);
    }
    return [];
  });

  const currentState = !application ? "unstarted" : getCurrentState(application);
  const department = useSelector((state) => selectDepartmentForRequirement(state, requirement));

  const { data: renewal = {}, isLoading: isRenewalLoading } = useRenewal(
    projectID,
    application.id,
    {
      enabled: contextType === "",
    },
  );
  const description = getDescription(requirement);
  const processingTime = getProcessingTime(requirement);
  const isIssuable = isRequirementIssuable(requirement);
  const showFeeAssesment = !wasSubmitted(application) && assessmentFields.length > 0;
  const showPizzaTracker =
    contextType !== "unstarted" &&
    !isNoLongerRequired(application) &&
    !isRenewalLoading &&
    !renewal?.showRenewalTimeline &&
    (isIssuable ? !wasProcessed(application) : wasIssued(application));
  returnTo = returnTo || `/projects/${projectID}/apply`;

  return (
    <Modal
      returnTo={returnTo}
      classNames={{ contentContainer: styles.modalContentContainer, content: styles.modalContent }}
    >
      <Layout.Page name="application-details">
        {readOnly && (
          <div className={styles.readOnlyContainer}>
            <ReadOnlyBadge />
          </div>
        )}
        <SkeuomorphicPaper pageFoldBgColor={modalBackgroundColor}>
          <div className={classnames(styles.container, styles.contentHeader)}>
            <h1 className={styles.header}>{getName(requirement)}</h1>
            {showPizzaTracker && (
              <div className={styles.progressBarSection}>
                <PizzaTracker
                  showUnsubmitted
                  isLoaded
                  lightBackground
                  hideUnderReview
                  isIssuable={isIssuable}
                  isPayableOnIssuance={isPayableOnIssuance(requirement)}
                  requirementApplicationID={application.id}
                  history={getStateHistory(application)}
                  isReviewTypeApproved={requirement.review_type === "Approved"}
                />
              </div>
            )}
            <div className={styles.descriptionSection}>
              <Markdown source={description || ""} />
            </div>
          </div>

          <Layout.ContentWithSidebar>
            <Layout.Content>
              <div className={styles.container}>
                {showFeeAssesment && (
                  <QuickNav
                    hasAfterYouApply={requirement.after_you_apply != null}
                    hasFeeFields={assessmentFields.length > 0}
                    hasAdditionalInfo={hasAdditionalInfo(department)}
                  />
                )}

                <div className={styles.statusContainer}>
                  {isRenewalLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {(renewal.showRenewalTimeline || renewal.showRenewalValidity) && (
                        <div className={styles.renewalContainer}>
                          {renewal.showRenewalValidity && (
                            <RenewalValidity requirementApplicationID={application.id} />
                          )}
                          {renewal.showRenewalTimeline && (
                            <RenewalTimeline requirementApplicationID={application.id} />
                          )}
                        </div>
                      )}

                      {renewal.showOutOfDate ? (
                        <div className={styles.outOfDateContainer}>
                          <RenewalOutOfDate requirementApplicationID={application.id} />
                        </div>
                      ) : (
                        <>
                          {renewal.renewable && (
                            <RenewalSection
                              requirement={requirement}
                              application={application}
                              department={department}
                              renewal={renewal}
                            />
                          )}
                          <Status
                            requirement={requirement}
                            application={application}
                            department={department}
                            currentState={currentState}
                            projectID={projectID}
                            wasDerivedSubmitted={!!renewal.derivedSubmittedAt}
                            contextType={contextType}
                          />
                        </>
                      )}
                    </>
                  )}

                  {wasSubmitted(application) && <LogEntriesSection application={application} />}
                </div>

                {showFeeAssesment && (
                  <div className={styles.feesSection}>
                    <h2 id="fee-assessment">
                      <Text t="projects.application.fees.section_title" />
                    </h2>
                    <div className={styles.feesDescription}>
                      <Text t="projects.application.fees.section_description" />
                    </div>
                    <Fields
                      fields={assessmentFields}
                      behaviorType="inline"
                      // FIXME: Locking behavior styling is broken, disabled for now.
                      // Layout={LayoutWithLock}
                    />
                  </div>
                )}
                {processingTime && (
                  <div className={styles.processingTimeSection}>
                    <h2>
                      <Text t="projects.application.processing_time" />
                    </h2>
                    <Markdown source={processingTime} />
                  </div>
                )}

                <AdditionalInfo department={department} />
              </div>
            </Layout.Content>

            <Layout.Sidebar>
              <div className={styles.summaryTableContainer}>
                <SummaryTable
                  requirementID={requirement.id}
                  interactive={false}
                  sticky={false} // we need to handle sticky ourselves to include the send message badge
                  unansweredFeeFieldsCount={0}
                />
                {!readOnly && (
                  <div className={styles.sendMessage}>
                    <SendMessageBadge application={application} />
                  </div>
                )}
              </div>
            </Layout.Sidebar>
          </Layout.ContentWithSidebar>

          <div className={styles.helpSection}>
            <HelpBar noPadding />
          </div>
        </SkeuomorphicPaper>
      </Layout.Page>

      <div className={styles.footer}>
        <Link className={styles.button} to={returnTo}>
          <Icon icon="times" size="lg" />
          <span>
            <Text t="close" />
          </span>
        </Link>
      </div>
    </Modal>
  );
};

const status = R.reduce((acc, e) => R.assoc(e, e, acc), {}, [
  "init",
  "in_progress",
  "error",
  "pending",
  "created",
]);
const currentStateFromLogEntries = R.compose(
  R.prop(R.__, {
    requirement_application_aca_submission_error: status.error,
    requirement_application_aca_submission_started: status.pending,
  }),
  R.prop("log_type"),
  R.last,
);

const ApplyViaAccela = ({ projectID, requirement, requirementApplicationID }) => {
  const accelaEnabled = useSelector(R.compose(isAccelaEnabled, selectTenant));
  const defaultGuidanceText = useText("projects.application.aca_guidance.detail");
  const accela_aca_guidance =
    useSelector(R.compose(getAccelaACAGuidanceMarkdown, selectTenant)) || defaultGuidanceText;
  const { accela_connected: isAccelaProfileConnected } = useSession();
  const [requestStatus, setRequestStatus] = useState(status.init);
  const applicationHistory = useApplicationHistory(requirementApplicationID);

  const logEntries = R.filter(
    R.anyPass([
      R.propEq("log_type", "requirement_application_aca_submission_error"),
      R.propEq("log_type", "requirement_application_aca_submission_started"),
    ]),
  )(applicationHistory);

  const stateFromLogEntries = currentStateFromLogEntries(logEntries);
  const submissionStartedLogEntry = R.compose(
    R.applySpec({
      exists: R.complement(isBlank),
      timed_out: R.compose(
        R.ifElse(isBlank, R.T, (momentTs) => now().isAfter(momentTs.add(10, "minutes"))),
        R.prop("timestamp"),
      ),
    }),
    R.defaultTo({}),
    R.findLast(R.propEq("log_type", "requirement_application_aca_submission_started")),
  )(logEntries);
  const pending =
    requestStatus === status.pending ||
    (stateFromLogEntries === status.pending && !submissionStartedLogEntry.timed_out);
  const hasError = requestStatus === status.error || stateFromLogEntries === status.error;

  // TODO: fix pending state
  const onBegin = async () => {
    try {
      setRequestStatus(status.in_progress);
      const result = await pushToAccela(projectID, requirement.id);
      if (result.status === 201) {
        // this represents the Legacy ACA approach; the ACA Record was created.
        setRequestStatus(status.created);
      } else {
        setRequestStatus(status.pending);
      }
    } catch (e) {
      setRequestStatus(status.error);
      reportError(e);
    }
  };

  if (requestStatus === status.created)
    return (
      // in this case we should not render this component anymore, as soon as the underlying state-machine state is updated
      <Loader />
    );

  if (!accelaEnabled)
    return (
      <Instruction
        icon="mouse-pointer"
        titleText={<Text t="projects.application.go_to_aca_to_apply.instruction" />}
        button={
          <ApplyViaLinkButton newTab url="/api/accela/redirect?to=citizen_access">
            <Text t="projects.application.go_to_aca_to_apply.cta" />
          </ApplyViaLinkButton>
        }
      />
    );

  return (
    <>
      <Instruction
        icon="pen-to-square"
        titleText={<Text t="projects.application.aca_guidance.heading" />}
      >
        <Markdown source={accela_aca_guidance} />
      </Instruction>

      <Instruction
        icon="mouse-pointer"
        titleText={<Text t="projects.application.aca_submission.connect.instruction" />}
        button={
          <ApplyViaLinkButton
            disabled={isAccelaProfileConnected}
            newTab
            url="/setup_accela_citizen_access"
          >
            {isAccelaProfileConnected ? (
              <>
                <Text t="projects.application.aca_submission.connect.cta.connected" />{" "}
                <Icon icon="check-circle" />
              </>
            ) : (
              <Text t="projects.application.aca_submission.connect.cta.connect" />
            )}
          </ApplyViaLinkButton>
        }
      />

      <Instruction
        icon="file-import"
        titleText={<Text t="projects.application.aca_submission.transmit.instruction" />}
        button={
          <Button
            onClick={onBegin}
            className={styles.button}
            disabled={!isAccelaProfileConnected || pending}
            isLoading={requestStatus === status.in_progress}
            label={
              pending ? (
                <>
                  <InlineLoader inverse />
                  <span>
                    <Text t="projects.application.aca_submission.transmit.cta.in_progress" />
                  </span>
                </>
              ) : (
                <Text t="projects.application.aca_submission.transmit.cta.transfer" />
              )
            }
          />
        }
      >
        {hasError && (
          <div className={styles.error}>
            <Icon icon="triangle-exclamation" />
            <span>
              <Text t="projects.application.aca_submission.error" />
            </span>
          </div>
        )}
      </Instruction>

      <Instruction
        icon="file-lines"
        titleText={<Text t="projects.application.aca_submission.complete_in_aca" />}
      />
    </>
  );
};

const AccelaTransferSuccess = ({ application }) => (
  <div className={classnames(styles.accelaSuccess)}>
    <div className={styles.subHeading}>
      <Icon icon="info-circle" size="xl" />
      <span>
        <Text t="projects.application.aca_transfer_success.header" />
      </span>
    </div>
    <p>
      <Text t="projects.application.aca_transfer_success.detail" />
    </p>
    <ExternalLink
      className={styles.button}
      href={`/api/accela/redirect?to=first_page&ra_id=${application.id}`}
      showIcon
    >
      <Text t="projects.application.aca_transfer_success.cta" />
    </ExternalLink>
  </div>
);

const ApplyInstructions = ({ projectID, requirement, application, department, contextType }) => {
  if (isMailIn(requirement) || isInPerson(requirement))
    return <ApplyMailInInstructions requirement={requirement} department={department} />;

  if (isIntegrated(requirement))
    return (
      <IntegratedApplyViaLink
        requirement={requirement}
        application={application}
        projectID={projectID}
        contextType={contextType}
      />
    );

  if (isAccelaCitizenAccess(requirement))
    return (
      <ApplyViaAccela
        requirement={requirement}
        projectID={projectID}
        requirementApplicationID={application.id}
      />
    );

  if (isExternalLinkPerDepartment(requirement))
    return (
      <Instruction
        icon="mouse-pointer"
        button={<ApplyViaLinkButton newTab url={getDownloadURL(requirement, department)} />}
      />
    );

  return null;
};
ApplyInstructions.propTypes = {
  projectID: PropTypes.number.isRequired,
  requirement: requirementPropType.isRequired,
  application: requirementApplicationPropType.isRequired,
  department: departmentPropType.isRequired,
};

const ApplyMailInInstructions = ({ department, requirement }) => {
  const url = getDownloadURL(requirement, department);
  const addressLines = R.compose(R.map(R.trim), R.split(","), getAddress)(department);
  const phone = getPhone(department);

  return (
    <>
      {!isBlank(url) && (
        <Instruction
          icon="download"
          titleText={<Text t="projects.application.download_application" />}
          button={
            <ApplyViaLinkButton newTab url={url}>
              <Text t="download" />
            </ApplyViaLinkButton>
          }
        />
      )}

      {isBlank(url) && (
        <Instruction
          icon="building"
          titleText={<Text t="projects.application.mail_in_obtain_requirement_instructions" />}
        >
          <address>
            <div className={styles.address}>
              {R.map(
                (line) => (
                  <div key={line}>{line}</div>
                ),
                addressLines,
              )}
            </div>
            <div className={styles.phone}>
              <TelephoneLink number={phone} />
            </div>
          </address>
        </Instruction>
      )}

      <Instruction
        icon="envelope"
        titleText={<Text t="projects.application.mail_in_instructions" />}
      >
        <address>
          <div className={styles.address}>
            {R.map(
              (line) => (
                <div key={line}>{line}</div>
              ),
              addressLines,
            )}
          </div>
          <div className={styles.phone}>
            <TelephoneLink number={phone} />
          </div>
        </address>
      </Instruction>
    </>
  );
};
ApplyMailInInstructions.propTypes = {
  department: departmentPropType,
  requirement: requirementPropType,
};

const ApplyViaLinkButton = ({
  url,
  newTab,
  children = <Text t="projects.handoff.apply_via_btn.apply" />,
  disabled = false,
}) => {
  const classes = classnames(styles.button, {
    [styles.disabled]: disabled,
  });

  if (isAbsolute(url) || newTab)
    return (
      <ExternalLink className={classes} disabled={disabled} href={url}>
        {children}
      </ExternalLink>
    );
  return (
    <Link className={classes} disabled={disabled} to={url} data-integrated-apply>
      {children}
    </Link>
  );
};
ApplyViaLinkButton.propTypes = {
  url: PropTypes.string.isRequired,
  newTab: PropTypes.bool,
};

const IntegratedApplyViaLink = ({ projectID, requirement, application }) => {
  if (isPayable(application))
    return (
      <Instruction
        icon="mouse-pointer"
        titleText={<Text t="projects.handoff.pay_then_apply" />}
        button={
          <ApplyViaLinkButton
            url={`/projects/${projectID}/requirements/${getSlug(
              requirement,
            )}/payment/${getInvoiceID(application)}`}
          >
            <Text t="projects.handoff.apply_via_btn.pay" />
          </ApplyViaLinkButton>
        }
      />
    );

  return (
    <Instruction
      icon="mouse-pointer"
      button={
        <ApplyViaLinkButton url={`/projects/${projectID}/requirements/${getSlug(requirement)}`}>
          <Text
            t={`projects.handoff.apply_via_btn.${
              requirement.type === "Upload" ? "upload" : "apply"
            }`}
          />
        </ApplyViaLinkButton>
      }
    />
  );
};

const PayableSection = ({ projectID, requirement, application }) => (
  <div className={styles.payableSection}>
    <div className={styles.subHeading}>
      <Icon icon="arrow-alt-circle-right" />
      <span>
        <Text t="projects.application.payment_due" />
      </span>
    </div>

    <p>
      <Text t="projects.application.ready_for_payment" />
    </p>

    <div className={styles.downloadButtons}>
      <Link
        className={styles.button}
        to={`/projects/${projectID}/requirements/${getSlug(requirement)}/payment/${getInvoiceID(
          application,
        )}`}
        data-pay-link
      >
        <Icon icon="usd-circle" />
        <span>
          <Text t="projects.handoff.apply_via_btn.pay" />
        </span>
      </Link>
    </div>
  </div>
);
PayableSection.propTypes = {
  requirement: requirementPropType.isRequired,
  projectID: PropTypes.number,
  application: requirementApplicationPropType.isRequired,
};

const SubmittedSection = ({ requirement, application, canonicalProjectID }) => (
  <div className={styles.submittedSection}>
    <div className={styles.subHeading}>
      <Icon icon="arrow-alt-circle-right" />
      <span>
        <Text t="projects.application.submitted" />
      </span>
    </div>
    <Markdown source={getNextStepsMarkdown(requirement)} />

    <div className={styles.downloadButtons}>
      <Download requirementApplicationID={application.id} projectID={canonicalProjectID} />
    </div>
  </div>
);
SubmittedSection.propTypes = {
  requirement: requirementPropType.isRequired,
  application: requirementApplicationPropType.isRequired,
  canonicalProjectID: PropTypes.number.isRequired,
};

const RejectedSection = ({ application }) => {
  const state = R.pipe(getStateHistory, R.last)(application);

  return (
    <div className={styles.rejectedSection}>
      <div className={styles.subHeading}>
        <Icon icon="times-octagon" />
        <span>
          <Text t="projects.application.rejected" />
        </span>
      </div>
      <StickyNoteBubble showSpeaker speakerName={getStateMetadata(["actor", "full_name"], state)}>
        <span>{getStateMetadata("note", state)}</span>
      </StickyNoteBubble>
    </div>
  );
};
RejectedSection.propTypes = {
  application: requirementApplicationPropType.isRequired,
};

const RevokedSection = ({ application }) => {
  const state = R.pipe(getStateHistory, R.last)(application);

  return (
    <div className={styles.revokedSection}>
      <div className={styles.subHeading}>
        <Icon icon="exclamation-triangle" />
        <span>
          <Text t="projects.application.revoked" />
        </span>
      </div>
      <StickyNoteBubble showSpeaker speakerName={getStateMetadata(["actor", "full_name"], state)}>
        <span>{getStateMetadata("note", state)}</span>
      </StickyNoteBubble>
    </div>
  );
};
RevokedSection.propTypes = {
  application: requirementApplicationPropType.isRequired,
};

const IssuedSection = ({ application, requirement, canonicalProjectID }) => (
  <div className={styles.issuedSection}>
    <div className={styles.subHeading}>
      <Icon icon="badge-check" />
      <span>
        <Text t="projects.application.issued" name={getName(requirement)} />
      </span>
    </div>
    <Text t="projects.application.issued_instructions" />

    <div className={styles.downloadButtons}>
      <Download
        requirementApplicationID={application.id}
        projectID={canonicalProjectID}
        hideApplication
      />
    </div>
  </div>
);
IssuedSection.propTypes = {
  application: requirementApplicationPropType.isRequired,
  requirement: requirementPropType.isRequired,
  canonicalProjectID: PropTypes.number.isRequired,
};

export default ApplicationDetails;
